define("ember-side-menu/utils/gestures", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calculateVelocity = calculateVelocity;
  exports.createGesture = createGesture;
  function calculateVelocity(start, end, deltaTime) {
    var delta = start - end;

    return delta / deltaTime;
  }

  function createGesture(startEvent, endEvent) {
    var time = endEvent.timeStamp - startEvent.timeStamp;
    var velocityX = calculateVelocity(startEvent.touches[0].pageX, endEvent.changedTouches[0].pageX, time);
    var velocityY = calculateVelocity(startEvent.touches[0].pageY, endEvent.changedTouches[0].pageY, time);

    return {
      time: time,
      velocityX: velocityX,
      velocityY: velocityY
    };
  }
});