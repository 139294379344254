define("@adopted-ember-addons/ember-stripe-elements/components/stripe-postal-code", ["exports", "@adopted-ember-addons/ember-stripe-elements/components/stripe-element", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-postal-code"], function (_exports, _stripeElement, _stripePostalCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _stripeElement.default.extend({
    layout: _stripePostalCode.default,
    classNames: ['ember-stripe-postal-code'],
    type: 'postalCode'
  });

  _exports.default = _default;
});