define("@adopted-ember-addons/ember-stripe-elements/components/stripe-card-expiry", ["exports", "@adopted-ember-addons/ember-stripe-elements/components/stripe-element", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-expiry"], function (_exports, _stripeElement, _stripeCardExpiry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _stripeElement.default.extend({
    layout: _stripeCardExpiry.default,
    classNames: ['ember-stripe-card-expiry'],
    type: 'cardExpiry'
  });

  _exports.default = _default;
});