define("@adopted-ember-addons/ember-stripe-elements/utils/stripe-mock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.stripeEventUtils = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var StripeMock = function StripeMock(publishableKey) {
    this.publishableKey = publishableKey;
  };

  StripeMock.prototype.elements = function () {
    return {
      create: function create() {
        return {
          mount: function mount() {},
          on: function on() {},
          unmount: function unmount() {}
        };
      }
    };
  };

  StripeMock.prototype.confirmCardPayment = function () {};

  StripeMock.prototype.createToken = function () {};

  StripeMock.prototype.createSource = function () {};

  StripeMock.prototype.createPaymentMethod = function () {};

  StripeMock.prototype.retrieveSource = function () {};

  StripeMock.prototype.paymentRequest = function () {};

  StripeMock.prototype.redirectToCheckout = function () {};

  StripeMock.prototype.retrievePaymentIntent = function () {};

  StripeMock.prototype.handleCardPayment = function () {};

  StripeMock.prototype.handleCardAction = function () {};

  StripeMock.prototype.confirmPaymentIntent = function () {};

  StripeMock.prototype.handleCardSetup = function () {};

  StripeMock.prototype.confirmCardSetup = function () {};

  StripeMock.prototype.retrieveSetupIntent = function () {};

  StripeMock.prototype.confirmSetupIntent = function () {};

  var cardArgs = {
    elementType: "card"
  };

  var baseArgs = _objectSpread(_objectSpread({}, cardArgs), {}, {
    "error": undefined,
    "value": {
      "postalCode": ""
    },
    "empty": true,
    "complete": false,
    "brand": "unknown"
  });

  var stripeError = {
    message: "Your card number is invalid.",
    type: "validation_error",
    code: "invalid_number"
  };

  var argsError = _objectSpread(_objectSpread({}, baseArgs), {}, {
    error: stripeError,
    "brand": "visa",
    "value": {
      "postalCode": "12345"
    }
  });

  var argsComplete = _objectSpread(_objectSpread({}, baseArgs), {}, {
    "complete": true
  });

  var stripeEventUtils = {
    triggerReady: function triggerReady(stripeElement) {
      stripeElement._emitEvent('ready'), cardArgs;
    },
    triggerBlur: function triggerBlur(stripeElement) {
      stripeElement._emitEvent('blur', cardArgs);
    },
    triggerFocus: function triggerFocus(stripeElement) {
      stripeElement._emitEvent('focus', cardArgs);
    },
    triggerIncomplete: function triggerIncomplete(stripeElement) {
      stripeElement._emitEvent('change', baseArgs);
    },
    triggerError: function triggerError(stripeElement) {
      var userArgs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      stripeElement._emitEvent('change', _objectSpread(_objectSpread({}, argsError), userArgs));
    },
    triggerComplete: function triggerComplete(stripeElement) {
      stripeElement._emitEvent('change', argsComplete);
    },
    triggerChange: function triggerChange(stripeElement) {
      var userArgs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      stripeElement._emitEvent('change', _objectSpread(_objectSpread({}, baseArgs), userArgs));
    }
  };
  _exports.stripeEventUtils = stripeEventUtils;
  var _default = StripeMock;
  _exports.default = _default;
});