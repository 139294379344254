define("@fortawesome/ember-fontawesome/templates/components/fa-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyKa3QhY",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"html\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fortawesome/ember-fontawesome/templates/components/fa-icon.hbs"
    }
  });

  _exports.default = _default;
});